import React, { useState, useEffect } from 'react';
import Wheel from './Wheel';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Home() {
  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showWheel, setShowWheel] = useState(false);
  useEffect(() => {
  if (window.Telegram) {
    const tg = window.Telegram.WebApp;

    tg.ready();
    tg.expand();
  }
}, []);

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const res = await axios.post('https://3008455-bj43204.twc1.net/api/wheel/validate-code', { code, name });
      if (res.data.valid) {
        toast.success('Код подтвержден!');
        setShowWheel(true);
        
      } else {
        toast.error('Неверный код!');
      }
    } catch (err) {
      toast.error('Ошибка при проверке кода');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-900 text-gray-100">
      
      {isLoading ? (
        <div className="text-xl text-blue-400 animate-pulse">Загрузка...</div>
      ) : showWheel ? (
        <Wheel code={code} />
      ) : (
        <form onSubmit={handleSubmit} className=" bg-gray-800 p-6 mx-4 rounded-lg shadow-lg">
          <h2 className="text-md mb-2 text-white font-bold">ФИО</h2>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Введите ваше ФИО"
            className="w-full p-3 mb-4 border text-sm border-gray-700 bg-gray-900 rounded-md text-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
          />
          <h2 className="text-md mb-2 text-white  font-bold">Код</h2>
          <input
            type="text"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            placeholder="Введите ваш код"
            className="w-full p-3 mb-6 text-sm border border-gray-700 bg-gray-900 rounded-md text-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
          />
            <button
              type="submit"
              className="px-6 py-3 w-full bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-200"
            >
            Отправить
          </button>
        </form>
      )}
    </div>
  );
}

export default Home;
