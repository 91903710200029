import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import './Wheel.css';

function Wheel({ code }) {
  const [prizes, setPrizes] = useState([]);
  const [spinning, setSpinning] = useState(false);
  const [selectedPrize, setSelectedPrize] = useState(null);
  const [rotation, setRotation] = useState(0);
  const [link, setLink] = useState('');
  const [log, setLog] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchPrizes = async () => {
      try {
        const res = await axios.get('https://3008455-bj43204.twc1.net/api/wheel/prizes');
        setPrizes(res.data);
      } catch (err) {
        toast.error('Не удалось загрузить призы');
      }
    };
    const linkPrizes = async () => {
      try {
        const res = await axios.get('https://3008455-bj43204.twc1.net/api/wheel/link');
        setLink(res.data.link);
      } catch (error) {
        toast.error('Не удалось найти ссылку');
      }
    };
    fetchPrizes();
    linkPrizes();
  }, []);

  const getAngle = (index) => {
    return (360 / prizes.length) * index;
  };

  const getRotationAngle = (index) => {
    const baseAngle = 360 * 6;
    const segmentAngle = 360 / prizes.length;
    const prizeAngle = index * segmentAngle;
    const offset = 30 / 2;
    return -(baseAngle + 90 + prizeAngle + offset);
  };

  const handleSpin = async () => {
    if (spinning) return;

    setSpinning(true);
    try {
      const res = await axios.post('https://3008455-bj43204.twc1.net/api/wheel/spin', { code });
      const { prize, log } = res.data;

      const prizeIndex = prizes.findIndex((p) => p.name === prize.name);
      const finalRotation = getRotationAngle(prizeIndex);

      setRotation(finalRotation);

      setTimeout(() => {
        setSelectedPrize(prize);
        setLog(log);
        setSpinning(false);
        setIsModalOpen(true);
      }, 6000);
    } catch (err) {
      toast.error('Не удалось покрутить колесо');
      setSpinning(false);
    }
  };

  const mask = (phoneNumber, number) => {
    if (!phoneNumber) return '';
    const phoneStr = phoneNumber.toString();
    const maskedSection = phoneStr.slice(0, number).replace(/./g, '*');
    const visibleSection = phoneStr.slice(number);
    return `${maskedSection}${visibleSection}`;
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedPrize(null);
  };

  return (
    <div className='bg-gray-900 overflow-hidden'>
      <div className="flex flex-col items-center h-screen bg-gray-900 py-8">
        <h1 className="text-xl  md:text-3xl mb-8 text-white font-bold">Колесо фортуны</h1>
        <a href={link} target='_blank' className="py-2 mb-5 w-32 text-center bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-200 cursor-pointer">ПРИЗЫ</a>
        <div className="wheel-container relative mb-8">
          <div className="wheel" style={{ transform: `rotate(${rotation}deg)` }}>
            {prizes.map((prize, index) => (
              <div key={index} className="wheel-segment" style={{ transform: `rotate(${getAngle(index)}deg)` }}>
                <div className="wheel-segment-content">
                  <div className="wheel-segment-name">{prize.name}</div>
                </div>
              </div>
            ))}
          </div>
          <div className="arrow"></div>
          <div className="center-circle"></div>
        </div>
        <button onClick={handleSpin} className="p-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-200" disabled={spinning}>
          Крутить колесо
        </button>

        {isModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="px-8 py-4 bg-white rounded-lg shadow-xl text-center w-5/6">
              <h2 className="text-md font-bold text-black">Поздравляем, ваш приз: {selectedPrize.name}!</h2>
              <p className="text-md font-bold text-black">(Сделайте скриншот и покажите этот экран администратору)</p>
              <img src={`https://3008455-bj43204.twc1.net/uploads/${selectedPrize.image}`} alt={selectedPrize.name} className="w-24 h-24 object-cover mt-4 mx-auto rounded-full border-4 border-blue-600" />
              {log && (
                <div className="mt-4 text-left text-black">
                  <p className="text-sm font-semibold">Номер операции: <span className="font-normal">{log.id}</span></p>
                  <p className="text-sm font-semibold">ФИО: <span className="font-normal">{log.username}</span></p>
                  <p className="text-sm font-semibold">Номер телефона: <span className="font-normal">{log.phoneNumber}</span></p>
                  {/* <p className="text-sm font-semibold">Сумма пополнения: <span className="font-normal">{log.orderAmount} БИН</span></p> */}
                  <p className="text-sm font-semibold">Код: <span className="font-normal">{log.code}</span></p>
                  <p className="text-sm font-semibold">Дата и время выигрыша: <span className="font-normal">{new Date(log.date).toLocaleString()}</span></p>
                </div>
              )}
              <button onClick={closeModal} className="mt-4 py-2 px-3 bg-gray-600 text-white rounded-lg hover:bg-gray-700 text-md transition duration-200">
                Закрыть
              </button>
            </div>
          </div>
        )}
        <button onClick={() => window.location.reload()} className="mt-6 p-3 bg-gray-600 text-white rounded-lg hover:bg-gray-700 transition duration-200">
          Назад
        </button>
      </div>
    </div>
  );
}

export default Wheel;
