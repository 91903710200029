import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from './components/Home';
import AdminLogin from './components/AdminLogin';
import AdminPanel from './components/AdminPanel';
import GenerateCode from './components/GenerateCode';
import ManagePrizes from './components/ManagePrizes';
import Logs from './components/Logs';
import Wheel from './components/Wheel';
import './index.css';
import { AuthContext } from './components/helpers/AuthContext';

function PrivateRoute({ children }) {
  const token = localStorage.getItem('token');
  return token ? children : <Navigate to="/admin" />;
}


function App() {
  const [auth, setAuth] = useState({
    username: "",
    id: 0,
    status: false,
    issuperadmin: false
  })
  
  useEffect(() => {
    const token = localStorage.getItem('token');
    axios.get('https://3008455-bj43204.twc1.net/api/auth', {
      headers: { Authorization: `Bearer ${token}` }
    }).then((response) => {
      if (response.data.error) {
        setAuth({ ...auth, status: false });
      } else {
        setAuth({
          username: response.data.auth.username,
          id: response.data.auth.id,
          status: true,
          issuperadmin: response.data.auth.issuperadmin
        });
      }
    });
  }, []);

  return (
  <AuthContext.Provider value={{ auth, setAuth }}>
    <Router>
      <div className="">
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/wheel" element={<Wheel />} />
            <Route path="/admin" element={<AdminLogin />} />
            <Route path="/admin/panel" element={<PrivateRoute><AdminPanel /></PrivateRoute>} />
            <Route path="/admin/generate-code" element={<PrivateRoute><GenerateCode /></PrivateRoute>} />
            <Route path="/admin/manage-prizes" element={<PrivateRoute><ManagePrizes /></PrivateRoute>} />
            <Route path="/admin/logs" element={<PrivateRoute><Logs /></PrivateRoute>} />
        </Routes>
      </div>
    </Router>
    </AuthContext.Provider>
  );
}

export default App;
